import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import DashboardLayout from "../../DashboardLayout";
import NotificationDataList from "./NotificationDataList";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const NotificationList = (porps) => {
  const { showAlert } = porps;
  const [refresh, setRefresh] = useState(false);
  return (
    <>
      <DashboardLayout>
        <Box sx={{ display: "flex" }}>
          <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: "auto" }}>
            <DrawerHeader />
            <NotificationDataList
              refresh={refresh}
              setRefresh={setRefresh}
              showAlert={showAlert}
            />
          </Box>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default NotificationList;
