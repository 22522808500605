import React, { useEffect } from "react";
import DashHeader from "./DashHeader";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DashboardLayout = (props) => {
  const { children, showAlert } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token") || !localStorage.getItem("id")) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div id="wrapper" className="theme-cyan overflow-hidden">
        <div className="overflow-hidden layout-container">
          <Box sx={{ display: "flex" }}>
            <DashHeader showAlert={showAlert} />
            <main style={{ width: "100%" }}>{children}</main>
          </Box>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
