import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, TextField, Grid, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Mode } from "@mui/icons-material";
import ServicesContext from "../../../ContextApi/ServiceContext";
import { useNavigate } from "react-router-dom";

const NotificationDataList = (props) => {
  const { refresh, setRefresh, showAlert } = props;
  const navigate = useNavigate();

  const [filterText, setFilterText] = useState("");
  const [seoData, setSeoData] = useState([]);

  let rows = seoData.map((item, index) => ({
    id: index + 1,
    Title: item.title,
    Description: item.description,
    Action: item,
  }));

  const columns = [
    { field: "id", headerName: "Sn", width: 70 },
    { field: "Title", headerName: "Title", width: 300 },
    { field: "Description", headerName: "Messages", width: 700 },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => (
        <>
          <div className="d-flex align-items-center justify-content-start">
            <Mode
              titleAccess="Edit"
              className="action-btn text-warning"
              onClick={() => handleEdit(params.row.Action)}
            />
            <Button onClick={() => handleDelete(params.row.Action._id)}>
              Mark as read
            </Button>
            {/* <RestoreFromTrash
              titleAccess="Delete"
              className="action-btn text-danger"
              onClick={() => handleDelete(params.row.Action._id)}
            /> */}
          </div>
        </>
      ),
      sortable: false,
      width: 100,
      valueGetter: (value, row) =>
        `${row.title || ""} ${row.description || ""}`,
    },
  ];

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(filterText.toLowerCase())
    )
  );

  // Get Products
  const { SeoState } = useContext(ServicesContext);
  const data = SeoState.getSeo();

  // get Data
  const getData = () => {
    data.then((res) => {
      setSeoData(res);
    });
  };

  // handleDelete
  const handleDelete = (id) => {
    try {
      if (window.confirm("Are you sure want to delete it ?") === true) {
        SeoState.deleteSeo(id);
        showAlert("success", "Items Deleted Successfully");
        setRefresh(!refresh);
      }
    } catch (error) {
      showAlert("danger", "OOPS!😢 Items Delete getting Error.");
    }
  };

  useEffect(() => {
    getData();
    setRefresh(refresh);
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <>
      <Grid style={{ overflow: "auto" }} container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" component="div">
            Notifications List
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          container
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{ marginRight: 10 }}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            overflow: "auto",
            minHeight: 600,
            maxHeight: 900,
            width: 100,
          }}
        >
          <DataGrid
            rows={filteredRows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 15]}
            checkboxSelection
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationDataList;
